import { useEffect, useState, type FunctionComponent } from 'react';
import { lineFriendUrl } from '~/constants/line';
import { useClientEnvContext } from '~/providers';
import styles from '~/styles/parts/loading.module.css';

const blockClass = 'bars';

type Props = {
  isFull?: boolean;
};

export const Loading: FunctionComponent<Props> = ({ isFull }) => {
  const [showError, setShowError] = useState(false);
  const { environment } = useClientEnvContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const reload = () => {
    window.location.reload();
  };

  const clickLineButton = () => {
    const url = lineFriendUrl(environment);
    window.location.replace(url);
  };

  return (
    <div className={`${styles.loading} ${isFull ? styles['is-full'] : ''}`}>
      <div className={styles[blockClass]}>
        {[...Array(6)].map((_, i) =>
        <span key={i} className={[styles[`${blockClass}__bar`], styles[`${blockClass}__bar--${i}`]].join(' ')} />
        )}
        {[...Array(6)].map((_, i) =>
        <span
          key={i}
          className={[
          styles[`${blockClass}__bar`],
          styles[`${blockClass}__bar--alt`],
          styles[`${blockClass}__bar--${i}--alt`]].
          join(' ')} />

        )}
      </div>
      {showError &&
      <>
          <p className={styles.announce}>処理に時間がかかっています</p>
          <button className={styles['retry-button']} onClick={reload}>
            もう一度試す
          </button>
          <button className={styles['line-button']} onClick={clickLineButton}>
            LINEでお問い合わせ
          </button>
        </>
      }
    </div>);

};